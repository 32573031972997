import { Injectable } from "@angular/core";
import { BaseApiService } from "../../../../../shared/services/_base-api.service";
import { NpsStore } from "../../../../services/nps.store";
import { BehaviorSubject, map, Observable, tap } from "rxjs";
import { SecondaryHubSimpleDto } from "../secondary-hub-simple.model";
import { SecondaryHubCompleteDto } from "../secondary-hub-complete.model";
import { SecondaryHubCreateByCategoryDto } from "../secondary-hub-create-by-category.model";
import { SecondaryHubCreateBySubcategoryDto } from "../secondary-hub-create-by-subcategory.model";
import { NpsConfigurationApiUrl } from "../../../shared/api/configuration.service";
import { ValidationRule } from "../../../../../shared/models/validation-rule";
import { SecondaryHubCategorySimpleResponseDto } from "../responses/secondary-hub-category-simple-response.model";

export const NpsSecondaryHubUrl = {
    OpSecondaryHubsGetList: () => "/api/operations/secondaryhubs/",
    OpSecondaryHubCategoriesGetList: () => "/api/operations/secondaryhubs/categories",
    OpSecondaryHubsGetById: (categoryId: string, subcategoryId: string) => `/api/operations/secondaryhubs/categories/${categoryId}/subcategories/${subcategoryId}`,
    OpSecondaryHubCreateByCategory: () => "/api/operations/secondaryhubs/categories",
    OpSecondaryHubCreateBySubcategory: (parentCategoryId: string) => `/api/operations/secondaryhubs/categories/${parentCategoryId}/subcategories`,
}

@Injectable({providedIn: 'root'})
export class SecondaryHubsService extends BaseApiService{

  private secondaryHubCategoriesCache$: BehaviorSubject<SecondaryHubCategorySimpleResponseDto[] | null> = new BehaviorSubject<SecondaryHubCategorySimpleResponseDto[] | null>(null);

  constructor(store: NpsStore) {
    super(store.apiUrl);
  }

  // todo snp make them all async

  getValidators(method: keyof typeof NpsSecondaryHubUrl, part: string = '') {
    return super.get<ValidationRule[]>(NpsConfigurationApiUrl.ConfigurationValidators(), { method, part });
  }

  secondaryHubCreateByCategory(sh: SecondaryHubCreateByCategoryDto) : Observable<SecondaryHubCompleteDto> {
    return super.post(NpsSecondaryHubUrl.OpSecondaryHubCreateByCategory(), sh);
  }

  secondaryHubCreateBySubcategory(parentCategoryId: string, sh: SecondaryHubCreateBySubcategoryDto) : Observable<SecondaryHubCompleteDto> {
    return super.post(NpsSecondaryHubUrl.OpSecondaryHubCreateBySubcategory(parentCategoryId), sh);
  }

  secondaryHubsGet() : Observable<SecondaryHubSimpleDto[]> {
    return super.get<SecondaryHubSimpleDto[]>(NpsSecondaryHubUrl.OpSecondaryHubsGetList());
  }

  secondaryHubsCategoriesGet(invalidateCache: boolean = false) : Observable<SecondaryHubCategorySimpleResponseDto[]> {
    if (!this.secondaryHubCategoriesCache$.value || invalidateCache) {
      return super.get<SecondaryHubCategoriesApiResponse[]>(NpsSecondaryHubUrl.OpSecondaryHubCategoriesGetList())
      .pipe(
        map(data => data.map(entry => {
            const mapProperty = new Map<string, string | undefined>(
              Object.entries(entry.subcategories)
            );

            return {...entry, subcategories: mapProperty };
          })
        ),
        tap(data => {
          this.secondaryHubCategoriesCache$.next(data);
        })
      );
    }
    else {
      return <Observable<SecondaryHubCategorySimpleResponseDto[]>> this.secondaryHubCategoriesCache$.asObservable();
    }
  }

  secondaryHubsCategoriesInvalidateCache() {
    this.secondaryHubCategoriesCache$.next(null);
  }

  secondaryHubGetByCategory(secondaryHubCategoryId: string, secondaryHubSubcategoryId: string) : Observable<SecondaryHubCompleteDto> {
    return super.get<SecondaryHubCompleteDto>(NpsSecondaryHubUrl.OpSecondaryHubsGetById(secondaryHubCategoryId, secondaryHubSubcategoryId));
  }

}

export class CategoryDropdownOption {
  name: string;
  value: string;

  constructor(value: string, description?: string) {
    this.name = `${value} (${description ?? ""})`;
    this.value = value;
  }
}

interface SecondaryHubCategoriesApiResponse {
  categoryId: string;
  categoryDescription: string;
  subcategories: { [key: string]: string | undefined };
}

